<template>
	<el-dialog :close-on-click-modal="false" destroy-on-close :title=" type == 0 ? '新增' : '编辑' " :visible.sync="show" width="450px">
		<el-form ref="form" :model="form" label-width="90px">
			<el-form-item label="角色名称" prop="name">
				<el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
			</el-form-item>
			<el-form-item label="角色" prop="level">
			    <el-select v-model="form.level" placeholder="请选择角色">
					<el-option v-for=" ( item , i ) in levellist " :key="i" :label="item.name" :value="item.level"></el-option>
			    </el-select>
			</el-form-item>
			<el-form-item label="启用状态" prop="status">
				<el-radio-group v-model="form.status">
					<el-radio :label="1">启用</el-radio>
					<el-radio :label="0">停用</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="描述信息" prop="description">
				<el-input type="textarea" placeholder="请输入描述信息" v-model="form.description"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit" style="margin-right: 20px;">{{ type == 0 ? '新增' : '编辑' }}</el-button>
				<el-button @click="show = false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { TextToCode } from 'element-china-area-data';
	export default {
		data() {
			return {
				show: false,
				form: {
					name : "",
					description: "",
					level : 1,
					status : 1,
				},
				type : 0,
				getRole_list : [],
				levellist:[
					{name:"app用户",level:0},
					{name:"后台管理系统用户",level:1},
				]
			}
		},
		methods: {
			open( data ) {
				this.reset_form_data();
				if( this.type != 0 ){
					this.form = Object.assign( {} , data );
				}else{
					this.form.level = this.user_info.level
				}
				this.show = true;
			},
			close() {
				this.show = false;
			},
			onSubmit() {
				this.$api[ this.type == 0 ? 'role_add' : 'role_update' ]( this.form ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: res.message
						});
						// this.$refs.level_select.value = 1;
						this.$refs.form.resetFields();
						this.$emit( 'success' , 'ok' );
						this.show = false;
					}
				});
			},
			handleChange( data ){
				for ( let k in data ) {
					this.form[ k ] = data[k];
				}
			},
			reset_form_data(){
				this.form = {
					name : "",
					description: "",
					level : 1,
					status : 1,
				}
			},
		},
	}
</script>

<style lang="scss">
	.el-dialog__header{
		background: linear-gradient(135deg, #6b6be4 , #15d8f1);
	}
	.el-dialog__title{
		color:#fff
	}
	.el-dialog__header{
		.el-dialog__headerbtn{
			.el-dialog__close{
				color: #fff;
			}
		}
	}
</style>
