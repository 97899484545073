<template>
	<div>
		<el-form :inline="true" :model="form">
			<el-form-item label="角色名">
				<el-input v-model="form.name" placeholder="请输入角色名"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="role_list" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-plus" @click="add_role" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<!-- <el-table-column
        prop="id"
        label="id"
        >
      </el-table-column> -->
			<el-table-column prop="name" label="角色名">
				<template slot-scope="scope">
					<el-tag>{{ scope.row.name }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="level" label="角色权限">
				>
				<template slot-scope="scope">
					<span v-if=" scope.row.level === 0 ">app用户</span>
					<span v-if=" scope.row.level === 1 ">后台管理系统用户</span>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态">
				>
				<template slot-scope="scope">
					<el-tag size="mini" :type="scope.row.status === 0 ? 'danger' : 'success'"
						disable-transitions>{{ scope.row.status === 0 ? '停用' : '启用' }}</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间">
			</el-table-column>
			<el-table-column prop="modifyTime" label="最后修改时间">
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="320">
				<template slot-scope="scope">
					<el-button size="mini" icon="el-icon-edit" type="primary" plain @click=" edit( scope.row ) "
						style="margin-right:5px">编辑</el-button>
					<el-popconfirm title="是否要删除？" icon-color="red" @confirm="delete_role( scope.row.id )">
						<el-button slot="reference" size="mini" type="danger" plain icon="el-icon-delete"
							style="margin-right:5px">删除</el-button>
					</el-popconfirm>
					<el-button type="success" icon="el-icon-menu" v-if="scope.row.level == 1"
						@click=" setMenu( scope.row.id ) " plain size="mini" style="margin-right:5px">分配菜单</el-button>
					<el-button type="warning" icon="el-icon-s-claim" v-if="scope.row.level == 1"
						@click=" setPermission( scope.row.id ) " plain size="mini">分配权限</el-button>

				</template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination @current-change="changePage" @size-change="sizeChange" :page-sizes="[ 10 , 20 , 30 , 40]"
				:total="total" :currentPage="form.startPage" :pageSize="form.pageSize" background
				layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>
		<edit ref="edit" :type="edit_type" @success="role_list" />
		<set-menu ref="setMenu" @success="role_list" />
		<set-permission ref="setPermission" @success="role_list" />
	</div>
</template>

<script>
	import edit from './components/edit.vue';
	import setMenu from './components/setMenu.vue';
	import setPermission from './components/setPermission.vue';
	export default {
		data() {
			return {
				loading: false,
				form: {
					name: "",
					pageSize: 10,
					startPage: 1,
					level: 1,
				},
				total: 0,
				list: [],
				edit_type: 0,
			}
		},
		created() {
			this.role_list();
		},
		methods: {
			role_list() {
				if (this.user_info.level != 1) {
					this.form.level = this.user_info.level;
				}
				this.loading = true;
				this.$api.role_list(this.form).then(res => {
					if (res.code == 200) {
						this.list = res.data.list;
						this.form.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					} else {
						this.list = [];
						this.form.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				});
			},
			setPermission(roleId) {
				this.$refs.setPermission.roleId = roleId;
				this.$refs.setPermission.open();
			},
			add_role() {
				this.$refs.edit.type = 0;
				this.$refs.edit.open();
			},
			edit(data) {
				this.$refs.edit.type = 1;
				this.$refs.edit.open(data);
			},
			delete_role(roleId) {
				this.$api.role_delete({
					roleId
				}).then(res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if (res.code == 200) {
						if (this.list.length == 1 && this.form.startPage > 1) {
							this.form.startPage = this.form.startPage - 1;
						}
						this.role_list();
					}
				});
			},
			setMenu(roleId) {
				this.$refs.setMenu.roleId = roleId;
				this.$refs.setMenu.open();
			},
			sizeChange(e) {
				this.form.pageSize = e;
				this.role_list();
			},
			changePage(e) {
				this.form.startPage = e;
				this.role_list();
			},
		},
		components: {
			edit,
			setMenu,
			setPermission
		}
	}
</script>