<template>
	<el-dialog :close-on-click-modal="false" title="分配菜单" :visible.sync="show" width="250px">
		<el-tree
			ref="tree"
			:data="getMenu_list"
			show-checkbox
			node-key="id"
			:default-expanded-keys="[1]"
			:props="defaultProps">
		</el-tree>
		<div class="mg text_c">
			<el-button size="mini" @click="setMenu" type="primary" style="margin-right: 20px;">确定</el-button>
			<el-button size="mini" @click="show = false">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				show : false,
				getMenu_list : [],
				roleId : "",
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		watch:{
			show(){
				if( !this.show ){
					this.$refs.tree.setCheckedKeys( [] );
				}
			}
		},
		methods:{
			open(){
				this.render();
			},
			close(){
				this.show = false;
			},
			onSubmit(){
				
			},
			setMenu(){
				let checked_menu_str_getHalf = this.$refs.tree.getHalfCheckedKeys(); //半选的父级
				let checked_menu_str_next = this.$refs.tree.getCheckedKeys(); //全选的父加子或者只选中的子集
				let checked_menu_str = checked_menu_str_next.concat( checked_menu_str_getHalf ) //整合为全部需要上传的数组
				checked_menu_str = JSON.stringify( checked_menu_str ); 
				let data = {
					roleId : this.roleId,
					menuList :checked_menu_str.substring( 1 , checked_menu_str.length - 1 ),
				}
				this.$api.role_setMenu( data ).then( res => {
					if( res.code == 200 ){
						this.close();
						this.$message({
							type: 'success',
							message: res.message
						});
						this.$emit( 'success' , 'ok' );
					}
				});
			},
			//获取该角色已经分配得菜单
			menu_get(){
				this.$api.menu_get( this.roleId ).then( res => {
					if( res.code == 200 ){
						for( var i = 0 ; i < res.data.length ; i++ ){
							if( res.data[i] > res.data[i+1] ){
								res.data.splice( i+1 , res.data.length-1 )
							}
						}
						this.$refs.tree.setCheckedKeys( res.data );
					}
				});
			},
			//渲染用户菜单列表 
			async render(){
				if( this.getMenu_list.length == 0 ){
					let menu_getMenu_res = await this.$api.menu_getMenu();
					if( menu_getMenu_res.code != 200 ){
						this.$message({
							type: 'error',
							message: res.message
						});
						return;
					}else{
						menu_getMenu_res.data.forEach( ( item , i ) => {
							if( item.parentId == 0 ){
								this.getMenu_list.push({
									id : item.id,
									label : item.title,
									children : []
								});
							}
						});
						menu_getMenu_res.data.forEach( ( item , i ) => {
							if( item.parentId != 0 ){
								this.getMenu_list.forEach( ( Menu_list_item , i ) => {
									if( item.parentId == Menu_list_item.id ){
										Menu_list_item.children.push({
											id : item.id,
											label : item.title
										})
									}
								});
							}
						});
					}
				}
				this.show = true;
				this.menu_get();
			}
		},
	}
</script>

<style>
</style>
