<template>
	<el-dialog :close-on-click-modal="false" custom-class="setPermission" title="分配权限" :visible.sync="show" width="802px">
		<el-scrollbar class=" layout_main_scrollbar " style="height: 400px;">
			<div v-for=" ( item , index ) in allocation_data " :key="index" >
				<div class="title">
					<el-divider content-position="left"><span style="color: #333;font-size: 18px;">{{ item.title }}</span><el-checkbox @change="p_change( index )" class="mg_l" v-model="item.check"></el-checkbox></el-divider>
				</div>
				<div class="box">
					<el-checkbox v-model="item.check" v-for="( item , i ) in item.data" @change="c_change( index )" :key="item.id">{{item.title}}</el-checkbox>
				</div>
			</div>
		</el-scrollbar>
		<div class="mg text_c">
			<el-button @click="setPermission_2" type="primary" style="margin-right: 20px;">确定</el-button>
			<el-button @click="show = false">取消</el-button>
		</div>
		<!-- <el-transfer
		    filterable
		    filter-placeholder="搜索查询"
		    v-model="value"
			:titles="['未分配权限', '已经分配权限']"
		    :data="data">
		</el-transfer>
		<div class="mg text_c">
			<el-button @click="setPermission" type="primary">确定</el-button>
			<el-button @click="show = false">取消</el-button>
		</div> -->
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				checked : false,
				data: [],
				value: [],
				show : false,
				roleId : "",
				allocation_data :[],
			}
		},
		methods:{
			p_change( index ){
				let check = this.allocation_data[index].check;
				this.allocation_data[index].data.forEach( item => {
					item.check = check ? true : false;
				});
			},
			c_change( index ){
				let check =  this.allocation_data[index].data.filter( item => {
					return item.check == true;
				});
				if( check.length == this.allocation_data[index].data.length ){
					this.allocation_data[index].check = true;
				}else{
					this.allocation_data[index].check = false;
				}
			},
			open(){
				this.allocation_data = [];
				//模式一
				// this.permission_getPermission();
				//模式二
				this.allocation();
			},
			close(){
				this.show = false;
			},
			async allocation(){
				let permission_getPermission_res = await this.$api.permission_getPermission();
				if( permission_getPermission_res.code != 200 ){
					this.$message({
						type: 'success',
						message: permission_getPermission_res.message
					});
					return;
				}
				permission_getPermission_res.data.forEach( item => {
					let size = this.allocation_data.filter( allocation =>  allocation.title == item.title ).length;
					if( size == 0 ){
						this.allocation_data.push({
							title : item.title,
							check_size : 0,
							data : [],
							check : false,
						});
					}
				});
				let permission_get_res = await this.$api.permission_get( this.roleId );
				let this_permission = permission_get_res.code == 200 ? permission_get_res.data : [];
				permission_getPermission_res.data.forEach( item => {
					this.allocation_data.forEach( allocation => {
						if( item.title == allocation.title ){
							let check = false;
							this_permission.forEach( this_permission => {
								if( item.id == this_permission ){
									check = !check;
									return;
								}
							});
							allocation.data.push({
								id : item.id,
								title : item.name,
								check,
							});
						}
					});
				});
				this.allocation_data.forEach( item => {
					let is_all_check = item.data.filter( data => data.check );
					if( is_all_check.length == item.data.length ){
						item.check = true;
					}
				});
				this.show = true;
			},
			permission_getPermission(){
				this.$api.permission_getPermission().then( res => {
					if( res.code == 200 ){
						this.data = [];
						res.data.forEach( ( item , i ) => {
							this.data.push({
								label: `${item.title}-${item.name}`,
								key: item.id,
								pinyin: `${item.title}-${item.name}`
							})
						});
						this.permission_get();
					}
				});
			},
			permission_get(){
				this.$api.permission_get( this.roleId ).then( res => {
					this.value = res.code == 200 ? res.data : [];
				});
			},
			setPermission_2(){
				let permissionList = [];
				this.allocation_data.forEach( item => {
					item.data.forEach( item => {
						if( item.check ){
							permissionList.push(item.id);
						}
					});
				});
				permissionList = JSON.stringify( permissionList );
				let data = {
					roleId : this.roleId,
					permissionList : permissionList.substring( 1 , permissionList.length - 1 ),
				}
				this.$api.role_setPermission( data ).then( res => {
					this.close();
					this.$message({
						type: 'success',
						message: res.message
					});
					this.$emit( 'success' , 'ok' );
				});
			},
			setPermission(){
				let value = JSON.stringify( this.value );
				let data = {
					roleId : this.roleId,
					permissionList : value.substring( 1 , value.length - 1 ),
				}
				this.$api.role_setPermission( data ).then( res => {
					this.close();
					this.$message({
						type: 'success',
						message: res.message
					});
					this.$emit( 'success' , 'ok' );
				});
			}
		},
	}
</script>

<style>
	
</style>
